<template>
  <v-dialog v-model="adminStore.isCustomerModalOpen" width="auto">
    <v-card class="main-dialog" width="1000">
      <div class="main-dialog__header">
        <div class="main-dialog__header-title">
          Користувач {{ adminStore.currentSellerInfo.name }}
        </div>
        <div class="main-dialog__header-id">
          ID # {{ adminStore.currentCustomerId }}
        </div>
        <v-icon
          @click="adminStore.handleCloseCustomerModal"
          class="icon main-dialog__header-close"
          >mdi-close</v-icon
        >
      </div>

      <div class="main-dialog__tabs">
        <div class="main-dialog__tabs-list">
          <v-tabs
            direction="vertical"
            v-model="tab"
            color="blue-darken-2"
            align-tabs="start"
          >
            <v-tab :value="1">Головна</v-tab>
            <v-tab :value="2">Відгуки</v-tab>
            <v-tab :value="3">Історія заявок</v-tab>
          </v-tabs>
        </div>

        <div class="main-dialog__tabs-wrapper">
          <v-tabs-window v-model="tab">
            <v-tabs-window-item :value="1">
              <AdminCustomerModalHomeStep />
            </v-tabs-window-item>
            <v-tabs-window-item :value="2">
              <AdminSellerModalReviewsStep
                :items="reviews"
                :rating="adminStore.currentCustomerInfo.rating"
                :title="`користувача ${adminStore.currentCustomerInfo.name}`"
              />
            </v-tabs-window-item>
            <v-tabs-window-item :value="3">
              <AdminSellerModalOrdesStep
                :items="orders"
                @changeStatus="updateByStatus"
              />
            </v-tabs-window-item>
          </v-tabs-window>
        </div>
      </div>

      <template v-slot:actions>
        <div class="main-dialog__actions">
          <v-btn
            v-if="adminStore.currentCustomerInfo.status === 'active'"
            @click="blockUser"
            color="red"
            block
            variant="outlined"
            >Заблокувати</v-btn
          >
          <v-btn
            v-else
            @click="unBlockUser"
            color="blue"
            block
            variant="outlined"
            >Розблокувати</v-btn
          >
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import AdminCustomerModalHomeStep from "@/components/Admin/AdminCustomerModalSteps/AdminCustomerModalHomeStep.vue";
import AdminSellerModalReviewsStep from "@/components/Admin/AdminSellerModalSteps/AdminSellerModalReviewsStep.vue";
import AdminSellerModalOrdesStep from "@/components/Admin/AdminSellerModalSteps/AdminSellerModalOrdesStep.vue";

import { useAdminStore } from "~/stores/useAdminStore";

const adminStore = useAdminStore();
const snackbarStore = useSnackbarStore();
const client = useSanctumClient();

const tab = ref(1);
const orders = ref([]);
const reviews = ref([]);

watch(
  () => adminStore.currentCustomerId,
  async (val) => {
    if (val) {
      await adminStore.getCustomerById(val);
      await fetchOrders();
      await fetchReviews();
    }
  },

  { deep: true }
);

const fetchOrders = async (status = 0) => {
  try {
    const response = await client(
      `api/admin/orders?customer_id=${adminStore.currentCustomerId}&status=${status}`
    );
    orders.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateByStatus = (status) => {
  fetchOrders(status);
};

const fetchReviews = async () => {
  try {
    const response = await client(
      `api/customers/${adminStore.currentCustomerId}/reviews`
    );
    reviews.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

const blockUser = async () => {
  try {
    await client(`api/admin/users/${adminStore.currentCustomerId}/block`, {
      method: "POST",
    });
    snackbarStore.setMessage({
      message: "Користуач успішно заблокований",
      color: "success",
    });
    snackbarStore.showMessage();
    await adminStore.getCustomerById(adminStore.currentCustomerId);
  } catch (error) {
    snackbarStore.setMessage({
      message: error.response._data.errors,
      color: "error",
    });
    snackbarStore.showMessage();
  }
};

const unBlockUser = async () => {
  try {
    await client(`api/admin/users/${adminStore.currentCustomerId}/unblock`, {
      method: "POST",
    });
    snackbarStore.setMessage({
      message: "Користуач успішно розблокований",
      color: "success",
    });
    snackbarStore.showMessage();
    await adminStore.getCustomerById(adminStore.currentCustomerId);
  } catch (error) {
    snackbarStore.setMessage({
      message: error.response._data.errors,
      color: "error",
    });
    snackbarStore.showMessage();
  }
};
</script>

<style scoped lang="scss">
.main-dialog {
  padding: 34px 0 12px;

  &__title {
    color: var(--color-primary-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.05px;
    margin-bottom: 24px;
  }

  &__header {
    padding: 0 24px;
    display: flex;
    align-items: center;
    gap: 28px;
    position: relative;
  }

  &__header-title {
    color: var(--color-main-dark);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__header-id {
    color: var(--color-main-dark);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__header-close {
    position: absolute;
    right: 24px;
    top: 0;
    color: var(--color-main-dark);
    cursor: pointer;
    font-size: 27px !important;
  }

  &__tabs {
    display: flex;
    gap: 24px;
    margin-top: 24px;
    border-bottom: 2px solid var(--color-border-default);
  }

  &__tabs-list {
    flex-shrink: 0;
    border-right: 2px solid var(--color-border-default);
    padding: 0 24px;
  }

  &__actions {
    display: inline-flex;
    padding: 12px 16px 0;

    button {
      min-width: 150px;
    }
  }

  &__tabs-wrapper {
    width: 100%;
    padding: 0 24px 40px 0;
  }
}
</style>

<style lang="scss">
.main-dialog__title {
  color: var(--color-primary-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.05px;
  margin-bottom: 24px;
}
</style>
