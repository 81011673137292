<template>
  <div class="admin-sidebar__wrapper">
    <div class="admin-sidebar">
      <component
        :is="item.children?.length ? 'div' : 'router-link'"
        v-for="(item, index) in sidebarLinkList"
        :key="index"
        :to="item.link"
        :disabled="item.disabled"
      >
        <div
          class="admin-sidebar__link"
          :class="{
            'admin-sidebar__link--active': route.path === item.link,
            'admin-sidebar__link--disabled': item.disabled,
          }"
          @click="item.open = !item.open"
        >
          <v-icon>mdi-{{ item.icon }}</v-icon>
          <span>{{ item.name }}</span>
          <v-icon
            class="icon admin-sidebar__link-icon"
            :class="{ 'admin-sidebar__link-icon--active': item.open }"
            v-if="item.children"
            >mdi-chevron-up</v-icon
          >
        </div>
        <div v-if="item.children?.length && item.open">
          <nuxt-link
            v-for="(link, lIndex) in item.children"
            :key="lIndex"
            :to="link.link"
            :disabled="link.disabled"
            class="admin-sidebar__link"
            :class="{
              'admin-sidebar__link--active': route.path === link.link,
              'admin-sidebar__link--disabled': item.disabled,
            }"
          >
            <div class="admin-sidebar__link--child">
              <v-icon>mdi-{{ link.icon }}</v-icon>
              <span>{{ link.name }}</span>
            </div>
          </nuxt-link>
        </div>
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";

const route = useRoute();

const sidebarLinkList = ref([
  {
    name: "Дашборд",
    link: "/admin/",
    icon: "view-dashboard-outline",
    disabled: false,
  },
  {
    name: "Користувачі",
    link: "/admin/users",
    icon: "clipboard-account-outline",
    disabled: false,
  },
  {
    name: "Компанії",
    link: "/admin/companies",
    icon: "clipboard-account-outline",
    disabled: false,
  },

  {
    name: "Заявки",
    link: "/admin/requests",
    icon: "clipboard-text",
    disabled: false,
  },
  {
    name: "Марки/моделі авто/Запчастини",
    icon: "car",
    disabled: false,
    open: false,
    children: [
      {
        name: "Марки",
        link: "/admin/cars",
        icon: "car",
        disabled: false,
      },
      {
        name: "Моделі",
        link: "/admin/cars/all-models",
        icon: "car",
        disabled: false,
      },
      {
        name: "Запчастини",
        link: "/admin/parts",
        icon: "car",
        disabled: false,
      },
    ],
  },

  // {
  //   name: "Відгуки",
  //   link: "/admin/reviews",
  //   icon: "message-draw",
  //   disabled: true,
  // },
  // {
  //   name: "Географія міст",
  //   link: "/admin/cities",
  //   icon: "map-marker-outline",
  //   disabled: true,
  // },
  // {
  //   name: "Знижки/Акції",
  //   link: "/admin/discounts",
  //   icon: "tag",
  //   disabled: true,
  // },
  // {
  //   name: "Блокування",
  //   link: "/admin/ban",
  //   icon: "cancel",
  //   disabled: true,
  // },
  // {
  //   name: "Статистика",
  //   link: "/admin/statistics",
  //   icon: "chart-timeline-variant-shimmer",
  //   disabled: true,
  // },
]);
</script>

<style scoped lang="scss">
.admin {
  &-sidebar {
    position: sticky;
    z-index: 0;
    display: flex;
    flex-direction: column;
    background: var(--color-main-white);
    z-index: 2;
    height: calc(100vh - 75px);
    overflow: auto;
    top: 75px;
  }

  &-sidebar__wrapper {
    flex-shrink: 0;
  }

  &-sidebar__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 32px;
    padding: 0 16px;
    min-height: 56px;

    color: var(--color-main-dark);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;

    transition: 0.3s;

    &--child {
      display: flex;
      align-self: center;
      gap: 32px;
      padding-left: 32px;
    }

    &-icon {
      transition: 0.3s;

      &--active {
        transform: rotate(180deg);
      }
    }

    span {
      flex-wrap: wrap;
      max-width: 200px;
      transition: 0.3s;
    }

    &:hover,
    &--active {
      background: #e3effa;

      span {
        color: #1976d2;
      }
    }

    &--disabled {
      pointer-events: none;
      cursor: not-allowed;
      color: #c4c4c4;
    }
  }
}
</style>
