<template>
  <v-dialog v-model="adminStore.isRequestModalOpen" width="auto">
    <v-card class="main-dialog" width="1000" height="830">
      <div class="main-dialog__header">
        <div class="main-dialog__header-title">
          Заявка №{{ adminStore.currentRequestId }}
        </div>
        <v-icon
          @click="adminStore.handleCloseRequestModal"
          class="icon main-dialog__header-close"
          >mdi-close</v-icon
        >
      </div>
      <div class="main-dialog__content">
        <v-tabs
          v-model="tab"
          class="my-offers__tabs mb-6"
          align-tabs="center"
          color="blue-darken-2"
        >
          <v-tab :value="1">Пропозиції</v-tab>
          <v-tab :value="2">Деталі заявки</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item :value="1">
            <div v-if="offers.length" class="my-offers__wrapper">
              <OfferCard
                v-for="item in offers"
                :key="item.id"
                :item="item"
                :showLinkg="false"
                class="my-offers__card my-offers__card--single"
                :class="{
                  'my-offers__card--confirmed':
                    item.status === SellerRequestStatus.CONFIRMED,
                  'my-offers__card--canceled':
                    item.status === SellerRequestStatus.CANCELED,
                }"
              />
            </div>
            <div v-else class="my-offers__wrapper">У вас немає пропозицій</div>
          </v-tabs-window-item>
          <v-tabs-window-item :value="2">
            <RequestDetails :id="adminStore.currentRequestId" />
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
      <div class="main-dialog__footer">
        <v-btn
          color="black"
          variant="outlined"
          @click="adminStore.handleCloseRequestModal"
          >Закрити</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { SellerRequestStatus } from "@/models/sellerRequests";
import { useAdminStore } from "~/stores/useAdminStore";

const adminStore = useAdminStore();
const client = useSanctumClient();
const tab = ref(1);

const info = ref({});
const offers = ref([]);

watch(
  () => adminStore.currentRequestId,
  async (value) => {
    tab.value = 1;

    const infoResponse = await client(`api/admin/orders/${value}`);
    info.value = infoResponse.data;

    const offersResponse = await client(
      `/api/customers/${info.value.customer.id}/orders/${value}/offers`
    );

    offers.value = offersResponse.data;
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
.main-dialog {
  &__content {
    padding: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 28px;
    position: relative;
    margin: 34px 24px;
  }

  &__header-title {
    color: var(--color-main-dark);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  &__header-close {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--color-main-dark);
    cursor: pointer;
    font-size: 27px !important;
  }

  &__divider {
    padding: 43px 0 32px;
    color: var(--color-primary-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.05px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 12;
    padding: 20px 40px;
    border-top: 1px solid #d9d9d9;
    margin-top: auto;
    gap: 12px;
  }
}

.my-offers {
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__card {
    width: calc(50% - 24px);

    &--single {
      width: 100%;
    }

    &--confirmed {
      border: 2px solid #62c554;
    }

    &--canceled {
      border: 2px solid #ff5252;
    }
  }
}
</style>
